<template>
    <div class="account-signup bg">
        <edit :customValidate="customValidate" :fs="fs" @saved="saved" apiSave="users" title="Create An Account">
            <template slot="submit" slot-scope="slotProps">
                <router-link tag="md-button" to="/account/login">Login</router-link>
                <submit :loading="slotProps.loading" icon="save" text="Signup"></submit>
            </template>
            <template slot="checkUserTerms" slot-scope="{ model, f }">
                <div class="edit-field" ref="checkUserTerms">
                    <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }">
                        <md-checkbox :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" v-model="model[f.data]" v-validate></md-checkbox>
                        <span style="margin-right:3px">I have read and agree to the</span>
                        <a @click.prevent="$refs['userTermsModal'].open()" href="/terms-of-service">Users Terms and Service Agreement</a>
                        <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
                    </md-input-container>
                </div>
            </template>
        </edit>
        <TermsOfServiceModal ref="userTermsModal"></TermsOfServiceModal>
    </div>
</template>

<script>
import Edit from "@/components/Edit";
import Submit from "@/components/Submit";
import TermsOfServiceModal from "@/components/modal/TermsOfServiceModal";
import { mapGetters } from "vuex";

export default {
    components: {
        Edit,
        Submit,
        TermsOfServiceModal
    },
    computed: {
        ...mapGetters({
            paymentSetting: "setting/payment"
        }),
        fs() {
            return [
                { heading: "Name", data: "name", type: "text", validate: "required" },
                { heading: "Company/Organization", data: "company", type: "text", validate: "required" },
                { heading: "Email", data: "email", type: "text", validate: "required|email" },
                { heading: "Password", data: "password", type: "password", validate: "required|secure-password", confirm: true },
                { heading: "I have read and agree to the Users Terms and Service Agreement", data: "checkUserTerms", type: "checkbox", validate: "agree|required" }
            ];
        }
    },
    methods: {
        saved(result) {
            if (result) {
                result.user.token = result.token;
                this.$store.commit("setMessage", "Signup Success");
                this.$store.commit("user/setUser", result.user);
                let user = result.user;

                // all vendor
                if (user.role == "vendor") {
                    if (user.locations.length == 0) {
                        this.$router.push("/account/vendor/location");
                        this.$store.commit("setMessage", "Please set service locations first");
                        return;
                    }
                }

                // master vendor
                if (user.role == "vendor" && !user.master_vendor_id) {
                    if (this.paymentSetting.value.use_tilled) {
                        if (!user.tilledId) {
                            this.$router.push("/admin/EzTimePay/cards/new");
                            this.$store.commit("setMessage", "Please add your card first");
                            return;
                        }
                        if (!user.tilledConnectId) {
                            this.$router.push("/account/profile");
                            this.$store.commit("setMessage", "Please connect EzTimePay account");
                            return;
                        }
                    } else if (this.paymentSetting.value.use_stripe) {
                        if (!user.stripeId) {
                            this.$router.push("/admin/stripe/cards/new");
                            this.$store.commit("setMessage", "Please add your card first");
                            return;
                        }
                        if (!user.stripeConnectId) {
                            this.$router.push("/account/profile");
                            this.$store.commit("setMessage", "Please connect stripe account");
                            return;
                        }
                    }
                }
                this.$router.push("/");
            }
        },
        async customValidate() {
            let result = await this.$validator.validateAll();
            return result;
        }
    }
};
</script>
<style lang="less">
.account-signup {
    min-height: 700px;
    .md-card {
        max-width: 1000px;
        margin: auto;
    }
    //fix checkbox
    .md-input-container {
        line-height: 41px;
        .md-error {
            line-height: 10px;
        }
    }
    .common-edit {
        .md-input-container {
            label {
                min-width: 200px;
            }
        }
    }
}
</style>
